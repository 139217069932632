import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core"
import React from "react"
import { formatMonetaryUsd, formatPercentage } from "./helpers"

function Stats(data: any) {
  return (
    <MuiTable aria-label="simple table" size="small">
      <TableBody>
        <TableRow>
          <TableCell style={{ fontWeight: 700 }} variant="head">
            Annual Revenue
          </TableCell>
          <TableCell align="right">
            {formatMonetaryUsd(data.data.annual_revenue)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontWeight: 700 }} variant="head">
            Marketing Spend
          </TableCell>
          <TableCell align="right">
            {formatMonetaryUsd(data.data.marketing_spend)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontWeight: 700 }} variant="head">
            Sales Spend Percentage of Revenue
          </TableCell>
          <TableCell align="right">
            {formatPercentage(data.data.revenue_percentage_sales_spend)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontWeight: 700 }} variant="head">
            Marketing Spend Percentage of Revenue
          </TableCell>
          <TableCell align="right">
            {formatPercentage(data.data.revenue_percentage_marketing_spend)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontWeight: 700 }} variant="head">
            Growth Rate Percentage
          </TableCell>
          <TableCell align="right">
            {formatPercentage(data.data.growth_rate_percentage)}
          </TableCell>
        </TableRow>
      </TableBody>
    </MuiTable>
  )
}

export default Stats
