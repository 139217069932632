import {
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  Grid,
  Typography,
} from "@material-ui/core"
import React, { useState } from "react"
import { CHART_DATA_STATE, ChartOptions, Charts } from "../Charts"
// import { formatMonetaryUsd } from "./helpers"

function Visuals(data: any) {
  const [chartData, setChartData] = useState(CHART_DATA_STATE)
  const [showChartDialog, setShowChartDialog] = useState(false)

  const toggleChartDialog = () => setShowChartDialog(!showChartDialog)
  const displayChartData = (config: ChartOptions) => {
    setChartData(config)
    setShowChartDialog(true)
  }

  const dataAnnualRevenues = data?.data
    ?.map((d: any) => (d.annual_revenue / 1000000).toFixed(0))
    .filter((v: string) => Number(v) > 0) // FIXME: formatMonetaryUsd
  const dataMarketingSpend = data?.data
    ?.map((d: any) => (d.marketing_spend / 1000000).toFixed(2))
    .filter((v: string) => Number(v) > 0) // FIXME: formatMonetaryUsd
  const dataFte: any = [0, 0, 0, 0, 0, 0, 0]
  data?.data?.forEach((d: any) => {
    if (d.fte === "0-100") dataFte[0]++
    if (d.fte === "100-250") dataFte[1]++
    if (d.fte === "250-500") dataFte[2]++
    if (d.fte === "500-1000") dataFte[3]++
    if (d.fte === "1000-5000") dataFte[4]++
    if (d.fte === "5000-10000") dataFte[5]++
    if (d.fte === ">10000") dataFte[6]++
  })

  return (
    <>
      <Grid
        container
        item
        spacing={2}
        justify="space-around"
        style={{ paddingRight: 16, paddingLeft: 16 }}
      >
        <Grid item xs={4}>
          <Card elevation={3}>
            <CardActionArea
              onClick={() =>
                displayChartData({
                  name: "Annual Revenues ($M)",
                  series: [
                    {
                      name: "Annual Revenues ($M)",
                      data: dataAnnualRevenues,
                    },
                  ],
                  type: "area",
                })
              }
            >
              <CardContent>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="center"
                >
                  Annual Revenues
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={3}>
            <CardActionArea
              onClick={() =>
                displayChartData({
                  name: "Marketing Spend ($M)",
                  series: [
                    {
                      name: "Marketing Spend ($M)",
                      data: dataMarketingSpend,
                    },
                  ],
                  type: "scatter",
                })
              }
            >
              <CardContent>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="center"
                >
                  Marketing Spend
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={3}>
            <CardActionArea
              onClick={() =>
                displayChartData({
                  name: "FTE",
                  series: [{ data: dataFte }],
                  type: "bar",
                  xAxisCategories: [
                    "0 - 100",
                    "100 - 250",
                    "250 - 500",
                    "500 - 1000",
                    "1000 - 5000",
                    "5000 - 10000",
                    "10000+",
                  ],
                })
              }
            >
              <CardContent>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="center"
                >
                  FTE
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={2}
        justify="space-around"
        style={{ paddingRight: 16, paddingLeft: 16 }}
      >
        <Grid item xs={4}>
          <Card elevation={3}>
            <CardActionArea>
              <CardContent>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="center"
                >
                  ...
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={3}>
            <CardActionArea>
              <CardContent>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="center"
                >
                  ...
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={3}>
            <CardActionArea>
              <CardContent>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="center"
                >
                  ...
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        maxWidth="lg"
        open={showChartDialog}
        onClose={toggleChartDialog}
        aria-labelledby="data-visualization"
      >
        <Charts
          name={chartData.name}
          series={chartData.series}
          type={chartData.type}
          xAxisCategories={chartData.xAxisCategories}
        />
      </Dialog>
    </>
  )
}

export default Visuals
