import Chart from "react-apexcharts"

interface ChartOptions {
  labels?: string[]
  name: string
  series: {
    data: number[]
    name?: string
  }[]
  type:
    | "area"
    | "line"
    | "bar"
    | "histogram"
    | "pie"
    | "donut"
    | "rangeBar"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "candlestick"
    | "radar"
    | "polarArea"
    | undefined
  xAxisCategories?: any[]
}

const CHART_DATA_STATE: ChartOptions = {
  name: "",
  series: [],
  type: undefined,
  xAxisCategories: undefined,
}

function Charts({ series, name, type, xAxisCategories }: ChartOptions) {
  const options = {
    series,
    chart: {
      type,
      height: 500,
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: name,
      },
      xaxis: {
        categories: xAxisCategories ?? series.map((_s, i) => i),
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  }
  return (
    <div style={{ padding: 8 }}>
      <Chart
        options={options.chart}
        series={options.series}
        type={type}
        width="600"
      />
    </div>
  )
}

export { Charts, CHART_DATA_STATE }
export type { ChartOptions }
