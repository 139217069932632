/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const postBenchmarks = /* GraphQL */ `
  mutation PostBenchmarks(
    $company_name: String
    $division: String
    $account_type: String
    $currency: String
    $fte: String
    $revenue_band: String
    $industry: String
    $grouped_industry: String
    $annual_revenue: Float
    $new_business_revenue: Float
    $sales_spend: Float
    $marketing_spend: Float
    $management_headcount: Int
    $sales_staff_headcount: Int
    $pipeline_coverage_ratio: Float
    $turnover: Float
    $ramp_time: Float
    $ramp_failure: Float
    $comp_entry_base: Int
    $comp_mid_base: Int
    $comp_senior_base: Int
    $comp_entry_variable: Int
    $comp_mid_variable: Int
    $comp_senior_variable: Int
    $quota_attainment: Float
    $win_rate: Float
    $rep_productivity: Float
    $deals_per_rep: Float
    $deal_size: Float
    $allocation_new_customer: Float
    $allocation_upsell: Float
    $allocation_cross_sell: Float
    $allocation_renewal: Float
    $cagr_annual: Float
    $cagr_3year: Float
    $cagr_5year: Float
    $customer_churn: Float
    $overall_revenue_churn: Float
    $recurring_revenue_churn: Float
    $cac: Float
    $ltv: Float
    $cac_into_ltv: Float
    $lead_conversion: Float
    $lead_cycle: Float
    $pricing_exceptions: Float
    $discount_percentage: Float
    $revenue_percentage_sales_spend: Float
    $revenue_percentage_marketing_spend: Float
    $span_of_control: Float
    $growth_rate_percentage: Float
  ) {
    postBenchmarks(
      company_name: $company_name
      division: $division
      account_type: $account_type
      currency: $currency
      fte: $fte
      revenue_band: $revenue_band
      industry: $industry
      grouped_industry: $grouped_industry
      annual_revenue: $annual_revenue
      new_business_revenue: $new_business_revenue
      sales_spend: $sales_spend
      marketing_spend: $marketing_spend
      management_headcount: $management_headcount
      sales_staff_headcount: $sales_staff_headcount
      pipeline_coverage_ratio: $pipeline_coverage_ratio
      turnover: $turnover
      ramp_time: $ramp_time
      ramp_failure: $ramp_failure
      comp_entry_base: $comp_entry_base
      comp_mid_base: $comp_mid_base
      comp_senior_base: $comp_senior_base
      comp_entry_variable: $comp_entry_variable
      comp_mid_variable: $comp_mid_variable
      comp_senior_variable: $comp_senior_variable
      quota_attainment: $quota_attainment
      win_rate: $win_rate
      rep_productivity: $rep_productivity
      deals_per_rep: $deals_per_rep
      deal_size: $deal_size
      allocation_new_customer: $allocation_new_customer
      allocation_upsell: $allocation_upsell
      allocation_cross_sell: $allocation_cross_sell
      allocation_renewal: $allocation_renewal
      cagr_annual: $cagr_annual
      cagr_3year: $cagr_3year
      cagr_5year: $cagr_5year
      customer_churn: $customer_churn
      overall_revenue_churn: $overall_revenue_churn
      recurring_revenue_churn: $recurring_revenue_churn
      cac: $cac
      ltv: $ltv
      cac_into_ltv: $cac_into_ltv
      lead_conversion: $lead_conversion
      lead_cycle: $lead_cycle
      pricing_exceptions: $pricing_exceptions
      discount_percentage: $discount_percentage
      revenue_percentage_sales_spend: $revenue_percentage_sales_spend
      revenue_percentage_marketing_spend: $revenue_percentage_marketing_spend
      span_of_control: $span_of_control
      growth_rate_percentage: $growth_rate_percentage
    )
  }
`;
