import { Button, Grid } from "@material-ui/core"
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik"
import React, { useEffect } from "react"
import {
  DataField,
  FIELDS,
  FORM_STATE,
  FORM_STATE_ACME,
  FORM_STATE_FOOBAR,
  FORM_STATE_QWERTY,
} from "./helpers"

const FormikAutoSave = () => {
  const formikContext = useFormikContext()
  useEffect(() => {
    console.log(formikContext)
    if (
      !!formikContext &&
      formikContext.values !== formikContext.initialValues
    ) {
      formikContext.submitForm()
    }
    // NOTE: you may get a message saying that this needs to pass `formik` into
    //       the array...which you should never do, as it crashes the browser!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikContext.values])
  return null
}

function Filters({
  fetchBenchmarks,
  setBenchmarkRecords,
  setIsFormDisabled,
  setFormSubmitting,
}: any) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={FORM_STATE}
        onReset={() => {
          setFormSubmitting(false)
          setIsFormDisabled(true)
          setBenchmarkRecords([])
        }}
        onSubmit={async (values) => {
          setIsFormDisabled(false)
          setFormSubmitting(true)
          // only fetch values that have been touched
          const valuesToFetch = Object.keys(values)
            .filter((k) => values[k].length)
            .reduce((a, k) => ({ ...a, [k]: values[k] }), {})
          if (Object.keys(valuesToFetch).length) {
            await fetchBenchmarks(valuesToFetch)
            setFormSubmitting(false)
          } else {
            setBenchmarkRecords([])
          }
        }}
      >
        {({ setValues }) => (
          <Form>
            <Grid container spacing={0}>
              <Grid container item spacing={2}>
                {FIELDS.map((field: DataField) => {
                  return (
                    <Grid item xs={4} key={field.field}>
                      <label style={{ width: "100%" }} htmlFor={field.field}>
                        {field.headerName}
                      </label>
                      <br />
                      <Field
                        style={{ width: "100%" }}
                        as="select"
                        name={field.field}
                      >
                        {field.options &&
                          field.options.map((option) => (
                            <option value={option.value} key={option.label}>
                              {option.label}
                            </option>
                          ))}
                      </Field>
                      <ErrorMessage name={field.field} component="div" />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-between"
              style={{ marginTop: 15, marginBottom: 30 }}
            >
              <Grid item justify="flex-start">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setValues(FORM_STATE_ACME)}
                  style={{ marginRight: 15 }}
                >
                  Example: ACME Corp
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setValues(FORM_STATE_QWERTY)}
                  style={{ marginRight: 15 }}
                >
                  Example: Qwerty Org
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setValues(FORM_STATE_FOOBAR)}
                  style={{ marginRight: 15 }}
                >
                  Example: Foobar Group
                </Button>
              </Grid>
              <Grid item>
                <Button type="reset" color="secondary" style={{ height: 30 }}>
                  Reset Form
                </Button>
              </Grid>
            </Grid>
            <FormikAutoSave />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Filters
