import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@material-ui/core"
import { API } from "aws-amplify"
import { ErrorMessage, Field, Form, Formik } from "formik"
import React, { useState } from "react"
import { mutations } from "../../graphql"

type Option = {
  label: string
  value: string
}

interface DataField {
  label: string
  name: string
  options?: Option[]
  type: string
}

const DB_COL_TYPES: DataField[] = [
  { label: "Company Name", name: "company_name", type: "text" },
  { label: "Division", name: "division", type: "text" },
  {
    label: "Ownership",
    name: "account_type",
    type: "select",
    options: [
      { label: "", value: "" },
      { label: "CORP", value: "CORP" },
      { label: "PE OWNED", value: "PE OWNED" },
      { label: "PE FIRM", value: "PE FIRM" },
      { label: "VC FIRM", value: "VC FIRM" },
      { label: "OTHER", value: "OTHER" },
    ],
  },
  { label: "Currency", name: "currency", type: "text" },
  {
    label: "FTE",
    name: "revenue_band",
    type: "select",
    options: [
      { label: "", value: "" },
      { label: "0 - 100", value: "0-100" },
      { label: "100 - 250", value: "100-250" },
      { label: "250 - 500", value: "250-500" },
      { label: "500 - 1000", value: "500-1000" },
      { label: "1000 - 5000", value: "1000-5000" },
      { label: "5000 - 10000", value: "5000-10000" },
      { label: "10000+", value: ">10000" },
    ],
  },
  {
    label: "Revenue Band",
    name: "fte",
    type: "select",
    options: [
      { label: "", value: "" },
      { label: "$0 - $100M", value: "0-100" },
      { label: "$100M - $250M", value: "100-250" },
      { label: "$250M - $500M", value: "250-500" },
      { label: "$500M - $1B", value: "500-1000" },
      { label: "$1B - $5B", value: "1000-5000" },
      { label: "$5B - $10B", value: "5000-10000" },
      { label: "$10B+", value: ">10000" },
    ],
  },
  {
    label: "Industry",
    name: "industry",
    type: "select",
    options: [
      { label: "", value: "" },
      { label: "Agriculture", value: "Agriculture" },
      { label: "Business Services", value: "Business Services" },
      { label: "Commercial Services", value: "Commercial Services" },
      { label: "Communications", value: "Communications" },
      { label: "Construction", value: "Construction" },
      { label: "Consumer Products", value: "Consumer Products" },
      { label: "Energy", value: "Energy" },
      { label: "Entertainment", value: "Entertainment" },
      { label: "Financial Services", value: "Financial Services" },
      { label: "Healthcare", value: "Healthcare" },
      { label: "IT Services", value: "IT Services" },
      { label: "Information Technology", value: "Information Technology" },
      { label: "Managed Network Services", value: "Managed Network Services" },
      { label: "Manufacturing", value: "Manufacturing" },
      { label: "Media", value: "Media" },
      { label: "Professional Services", value: "Professional Services" },
      { label: "Retail", value: "Retail" },
      { label: "Software", value: "Software" },
      { label: "Technology", value: "Technology" },
      { label: "Telecommunications", value: "Telecommunications" },
      { label: "Transportation", value: "Transportation" },
    ],
  },
  { label: "Grouped Industry", name: "grouped_industry", type: "text" },
  { label: "Annual Revenue", name: "annual_revenue", type: "decimal" },
  {
    label: "New Business Revenue",
    name: "new_business_revenue",
    type: "decimal",
  },
  { label: "Sales spend", name: "sales_spend", type: "decimal" },
  { label: "Marketing Spend", name: "marketing_spend", type: "decimal" },
  {
    label: "Management headcount",
    name: "management_headcount",
    type: "integer",
  },
  {
    label: "Sales staff headcount",
    name: "sales_staff_headcount",
    type: "integer",
  },
  {
    label: "Pipeline Coverage Ratio",
    name: "pipeline_coverage_ratio",
    type: "decimal",
  },
  { label: "Turnover", name: "turnover", type: "decimal" },
  { label: "Ramp Time", name: "ramp_time", type: "decimal" },
  { label: "Ramp Failure", name: "ramp_failure", type: "decimal" },
  { label: "Comp Entry Base", name: "comp_entry_base", type: "integer" },
  { label: "Comp Mid Base", name: "comp_mid_base", type: "integer" },
  { label: "Comp Senior Base", name: "comp_senior_base", type: "integer" },
  {
    label: "Comp Entry Variable",
    name: "comp_entry_variable",
    type: "integer",
  },
  { label: "Comp Mid Variable", name: "comp_mid_variable", type: "integer" },
  {
    label: "Comp Senior Variable",
    name: "comp_senior_variable",
    type: "integer",
  },
  { label: "Quota attainment", name: "quota_attainment", type: "decimal" },
  { label: "Win rate", name: "win_rate", type: "decimal" },
  { label: "Rep Productivity", name: "rep_productivity", type: "decimal" },
  { label: "Deals per rep", name: "deals_per_rep", type: "decimal" },
  { label: "Deal size", name: "deal_size", type: "decimal" },
  {
    label: "Allocation New Customer",
    name: "allocation_new_customer",
    type: "decimal",
  },
  { label: "Allocation Upsell", name: "allocation_upsell", type: "decimal" },
  {
    label: "Allocation Cross-Sell",
    name: "allocation_cross_sell",
    type: "decimal",
  },
  { label: "Allocation Renewal", name: "allocation_renewal", type: "decimal" },
  { label: "CAGR (Annual)", name: "cagr_annual", type: "decimal" },
  { label: "CAGR (3 Year)", name: "cagr_3year", type: "decimal" },
  { label: "CAGR (5 Year)", name: "cagr_5year", type: "decimal" },
  { label: "Customer Churn", name: "customer_churn", type: "decimal" },
  {
    label: "Overall Revenue Churn",
    name: "overall_revenue_churn",
    type: "decimal",
  },
  {
    label: "Recurring Revenue Churn",
    name: "recurring_revenue_churn",
    type: "decimal",
  },
  { label: "CAC", name: "cac", type: "decimal" },
  { label: "LTV", name: "ltv", type: "decimal" },
  { label: "CAC into LTV", name: "cac_into_ltv", type: "decimal" },
  { label: "Lead conversion", name: "lead_conversion", type: "decimal" },
  { label: "Lead Cycle", name: "lead_cycle", type: "decimal" },
  { label: "Pricing exceptions", name: "pricing_exceptions", type: "decimal" },
  {
    label: "Discount percentage",
    name: "discount_percentage",
    type: "decimal",
  },
  {
    label: "Revenue percentage Sales Spend",
    name: "revenue_percentage_sales_spend",
    type: "decimal",
  },
  {
    label: "Revenue percentage Marketing Spend",
    name: "revenue_percentage_marketing_spend",
    type: "decimal",
  },
  { label: "Span of Control", name: "span_of_control", type: "decimal" },
  {
    label: "Growth Rate Percentage",
    name: "growth_rate_percentage",
    type: "decimal",
  },
]

const FORM_STATE: any = {
  company_name: undefined,
  division: undefined,
  account_type: undefined,
  currency: undefined,
  fte: undefined,
  industry: undefined,
  grouped_industry: undefined,
  annual_revenue: undefined,
  new_business_revenue: undefined,
  sales_spend: undefined,
  marketing_spend: undefined,
  management_headcount: undefined,
  sales_staff_headcount: undefined,
  pipeline_coverage_ratio: undefined,
  turnover: undefined,
  ramp_time: undefined,
  ramp_failure: undefined,
  comp_entry_base: undefined,
  comp_mid_base: undefined,
  comp_senior_base: undefined,
  comp_entry_variable: undefined,
  comp_mid_variable: undefined,
  comp_senior_variable: undefined,
  quota_attainment: undefined,
  win_rate: undefined,
  rep_productivity: undefined,
  deals_per_rep: undefined,
  deal_size: undefined,
  allocation_new_customer: undefined,
  allocation_upsell: undefined,
  allocation_cross_sell: undefined,
  allocation_renewal: undefined,
  cagr_annual: undefined,
  cagr_3year: undefined,
  cagr_5year: undefined,
  customer_churn: undefined,
  overall_revenue_churn: undefined,
  recurring_revenue_churn: undefined,
  cac: undefined,
  ltv: undefined,
  cac_into_ltv: undefined,
  lead_conversion: undefined,
  lead_cycle: undefined,
  pricing_exceptions: undefined,
  discount_percentage: undefined,
  revenue_percentage_sales_spend: undefined,
  revenue_percentage_marketing_spend: undefined,
  span_of_control: undefined,
  growth_rate_percentage: undefined,
}

function Edit({ onClose }: any) {
  const [formValues, setFormValues] = useState(FORM_STATE)
  const [formValuesCopy, setFormValuesCopy] = useState({})

  async function postCompany(values: any) {
    try {
      const res: any = await API.graphql({
        query: mutations.postBenchmarks,
        variables: values,
      })
      console.log(res.data)
      if (res.data.postBenchmarks === "{numberOfRecordsUpdated=1}") {
        alert(`Added: ${values.company_name}`)
        setFormValues(FORM_STATE)
        setFormValuesCopy(FORM_STATE)
        onClose()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <DialogContent>
        <DialogContentText variant="body2">
          <em>
            Add a new benchmark to the demo database with any number of data
            points.
            <br />
            Successful entries can be filtered immediately upon submission.
            <br />
            -- BusheyBot&trade;
          </em>
        </DialogContentText>
        <Formik
          enableReinitialize={true}
          initialValues={formValues}
          onReset={() => {
            setFormValues(FORM_STATE)
            setFormValuesCopy(FORM_STATE)
          }}
          validate={(formValues: any) => {
            setFormValuesCopy(formValues)
          }}
          validateOnBlur
          validateOnChange
          onSubmit={() => {
            return
          }}
        >
          {() => (
            <Form>
              <Grid container spacing={0} style={{ marginTop: 30 }}>
                {DB_COL_TYPES.map((field) => (
                  <Grid
                    item
                    xs={6}
                    key={field.name}
                    style={{ marginBottom: 10 }}
                  >
                    <label htmlFor={field.name}>
                      <small>{field.label}</small>
                    </label>
                    <br />
                    {field.type === "decimal" ? (
                      <Field
                        style={{ width: "90%" }}
                        type="number"
                        name={field.name}
                        step="0.01"
                      />
                    ) : field.type === "select" ? (
                      <Field
                        style={{ width: "90%" }}
                        as="select"
                        name={field.label}
                      >
                        {field.options &&
                          field.options.map((option) => {
                            return (
                              <option value={option.value} key={option.label}>
                                {option.label}
                              </option>
                            )
                          })}
                      </Field>
                    ) : field.type === "integer" ? (
                      <Field
                        style={{ width: "90%" }}
                        type="number"
                        name={field.name}
                        step="1"
                      />
                    ) : (
                      <Field
                        style={{ width: "90%" }}
                        type="text"
                        name={field.name}
                      />
                    )}
                    <ErrorMessage name={field.name} component="div" />
                  </Grid>
                ))}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={async () => await postCompany(formValuesCopy)}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </>
  )
}

export default Edit
