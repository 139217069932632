import {
  Grid,
  LinearProgress,
  Table as MuiTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core"
import { API } from "aws-amplify"
import React, { useEffect, useState } from "react"
import { queries } from "../../graphql"
import Filters from "./Filters"
import Stats from "./Stats"
import Visuals from "./Visuals"
import {
  BENCHMARK_COLS,
  FIELDS,
  PEER_GROUP_STATS,
  SUMMARY_COLS,
  formatMonetaryUsd,
  formatPercentage,
  // styles,
} from "./helpers"

function Search() {
  const [benchmarkRecords, setBenchmarkRecords] = useState([])
  const [benchmarkPeerGroupStats, setBenchmarkPeerGroupStats] = useState(
    PEER_GROUP_STATS,
  )
  const [isFormDisabled, setIsFormDisabled] = useState(true)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(500)

  const handleChangePage = (_event: any, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  async function fetchBenchmarks(values: any) {
    try {
      // req
      const benchmarks: any = await API.graphql({
        query: queries.getBenchmarksWithArgs,
        variables: values,
      })
      const res = benchmarks.data.getBenchmarksWithArgs
      if (res.length) {
        // data cleanup for output
        res.forEach((r: any) => {
          delete r.id
          delete r.company_id
        })
      }
      // populate table
      setBenchmarkRecords(res)
    } catch (err) {
      console.log("Error fetching GraphQL:", err)
    }
  }

  // TODO: might want to make this something that we can pass different types
  useEffect(() => {
    if (benchmarkRecords.length) {
      const newStats: any = {}
      SUMMARY_COLS.forEach((c: string) => {
        const total: number = benchmarkRecords.reduce((acc: any, curr: any) => {
          return acc + Number(curr[c])
        }, 0)
        const value = (total / benchmarkRecords.length).toFixed(2)
        newStats[c] = value
      })
      setBenchmarkPeerGroupStats(newStats)
    } else {
      setBenchmarkPeerGroupStats(PEER_GROUP_STATS)
    }
  }, [benchmarkRecords])

  // const tableRef: any = useRef()
  // function scrollTable(value: number) {
  //   tableRef.current.scrollLeft += value
  // }

  return (
    <div style={{ paddingBottom: 60 }}>
      <Typography style={{ marginBottom: 5 }} variant="subtitle1">
        Search Filters
      </Typography>
      <Filters
        fetchBenchmarks={fetchBenchmarks}
        setBenchmarkRecords={setBenchmarkRecords}
        setFormSubmitting={setFormSubmitting}
        setIsFormDisabled={setIsFormDisabled}
      />

      <Typography style={{ marginBottom: 5 }} variant="subtitle1">
        Peer Group Averages
      </Typography>

      <Paper
        elevation={3}
        style={{
          opacity: formSubmitting ? 0.4 : 1,
          marginBottom: 30,
        }}
      >
        <Grid container>
          <Grid item xs={5}>
            <Stats data={benchmarkPeerGroupStats} />
          </Grid>
          <Grid
            container
            item
            xs={7}
            justify="space-evenly"
            direction="column"
            style={{ backgroundColor: "#fafafa" }}
          >
            <Visuals data={benchmarkRecords} />
          </Grid>
        </Grid>
      </Paper>

      <Grid container alignItems="center">
        <Typography
          style={{ marginRight: 30, marginBottom: 5 }}
          variant="subtitle1"
        >
          Search Results ({benchmarkRecords.length})
        </Typography>
        {formSubmitting && !isFormDisabled && (
          <LinearProgress style={{ top: -3, width: 200 }} />
        )}
        {/* <>
          <Button
            size="small"
            style={{ minWidth: 40 }}
            onClick={() => scrollTable(-750)}
          >
            &larr;
          </Button>
          <Button
            size="small"
            style={{ minWidth: 40 }}
            onClick={() => scrollTable(750)}
          >
            &rarr;
          </Button>
        </> */}
      </Grid>

      <TableContainer
        component={Paper}
        elevation={3}
        // innerRef={tableRef}
        style={{
          opacity: formSubmitting ? 0.4 : 1,
          maxHeight: "70vh",
        }}
      >
        <MuiTable aria-label="simple table" size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(BENCHMARK_COLS).map((k) => (
                <TableCell style={{ fontWeight: 700, minWidth: 120 }} key={k}>
                  {BENCHMARK_COLS[k]}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {benchmarkRecords ? (
              benchmarkRecords
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, i) => (
                  <TableRow key={i}>
                    {Object.keys(row).map((k) => {
                      let val: any = row[k]
                      // in some cases, use the label format vs the raw value
                      if (["fte", "revenue_band", "cagr_3year"].includes(k)) {
                        const match = FIELDS.filter(
                          (f) => f.field === k,
                        )[0].options.filter((o) => o.value === row[k])
                        val = match.length ? match[0].label : row[k]
                      }
                      // in other cases, transform values to percentages
                      if (
                        [
                          // "turnover",
                          // "quota_attainment",
                          // "win_rate",
                          "discount_percentage",
                          "revenue_percentage_sales_spend",
                          "revenue_percentage_marketing_spend",
                          "growth_rate_percentage",
                        ].includes(k)
                      ) {
                        val = !!row[k]
                          ? formatPercentage(row[k].toString())
                          : ""
                      }
                      // in other cases, transform values to monetary values
                      if (
                        [
                          "annual_revenue",
                          "new_business_revenue",
                          "sales_spend",
                          "marketing_spend",
                          "deal_size",
                        ].includes(k)
                      ) {
                        val = !!row[k]
                          ? formatMonetaryUsd(row[k].toString())
                          : ""
                      }
                      return (
                        <TableCell scope="row" key={k}>
                          {val}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))
            ) : (
              <TableRow key="asdf">
                <TableCell scope="row"></TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[25, 100, 500]}
        component="div"
        count={benchmarkRecords.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default Search
