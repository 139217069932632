type Option = {
  label: string
  value: string
}

interface DataField {
  field: string
  headerName: string
  options: Option[]
  type: string
}

const BENCHMARK_COLS: any = {
  division: "Division",
  account_type: "Ownership",
  currency: "Currency",
  fte: "FTE",
  revenue_band: "Revenue Band",
  industry: "Industry",
  grouped_industry: "Grouped Industry",
  annual_revenue: "Annual Revenue",
  new_business_revenue: "New Business Revenue",
  sales_spend: "Sales Spend",
  marketing_spend: "Marketing Spend",
  management_headcount: "Management Headcount",
  sales_staff_headcount: "Sales Staff Headcount",
  pipeline_coverage_ratio: "Pipeline Coverage Ratio",
  turnover: "Turnover",
  ramp_time: "Ramp Time",
  ramp_failure: "Ramp Failure",
  comp_entry_base: "Comp Entry Base",
  comp_mid_base: "Comp Mid Base",
  comp_senior_base: "Comp Senior Base",
  comp_entry_variable: "Comp Entry Variable",
  comp_mid_variable: "Comp Mid Variable",
  comp_senior_variable: "Comp Senior Variable",
  quota_attainment: "Quota Attainment",
  win_rate: "Win Rate",
  rep_productivity: "Rep Productivity",
  deals_per_rep: "Deals Per Rep",
  deal_size: "Deal Size",
  allocation_new_customer: "Allocation New Customer",
  allocation_upsell: "Allocation Upsell",
  allocation_cross_sell: "Allocation Cross Sell",
  allocation_renewal: "Allocation Renewal",
  cagr_annual: "CAGR (Annual)",
  cagr_3year: "CAGR (3 Year)",
  cagr_5year: "CAGR (5 Year)",
  customer_churn: "Customer Churn",
  overall_revenue_churn: "Overall Revenue Churn",
  recurring_revenue_churn: "Recurring Revenue Churn",
  cac: "CAC",
  ltv: "LTV",
  cac_into_ltv: "CAC into LTV",
  lead_conversion: "Lead Conversion",
  lead_cycle: "Lead Cycle",
  pricing_exceptions: "Pricing Exceptions",
  discount_percentage: "Discount Percentage",
  revenue_percentage_sales_spend: "Sales Spend Percentage of Revenue",
  revenue_percentage_marketing_spend: "Marketing Spend Percentage of Revenue",
  span_of_control: "Span Of Control",
  growth_rate_percentage: "Growth Rate Percentage",
}

const SUMMARY_COLS: any = [
  "annual_revenue",
  "sales_spend",
  "marketing_spend",
  "cagr_annual",
  "cagr_3year",
  "cagr_5year",
  "revenue_percentage_sales_spend",
  "revenue_percentage_marketing_spend",
  "growth_rate_percentage",
]

const PEER_GROUP_STATS: any = SUMMARY_COLS.map((c: string) => ({ [c]: "" }))

const FIELDS: DataField[] = [
  {
    field: "account_type",
    headerName: "Ownership",
    options: [
      { label: "", value: "" },
      { label: "CORP", value: "CORP" },
      { label: "PE OWNED", value: "PE OWNED" },
      { label: "PE FIRM", value: "PE FIRM" },
      { label: "VC FIRM", value: "VC FIRM" },
      { label: "OTHER", value: "OTHER" },
    ],
    type: "select",
  },
  {
    field: "fte",
    headerName: "FTE",
    options: [
      { label: "", value: "" },
      { label: "0 - 100", value: "0-100" },
      { label: "100 - 250", value: "100-250" },
      { label: "250 - 500", value: "250-500" },
      { label: "500 - 1000", value: "500-1000" },
      { label: "1000 - 5000", value: "1000-5000" },
      { label: "5000 - 10000", value: "5000-10000" },
      { label: "10000+", value: ">10000" },
    ],
    type: "select",
  },
  {
    field: "revenue_band",
    headerName: "Annual Revenue Band",
    options: [
      { label: "", value: "" },
      { label: "$0 - $100M", value: "0-100" },
      { label: "$100M - $500M", value: "100-500" },
      // { label: "$100M - $250M", value: "100-250" },
      // { label: "$250M - $500M", value: "250-500" },
      { label: "$500M - $1B", value: "500-1000" },
      { label: "$1B+", value: ">1000" },
      // { label: "$1B - $5B", value: "1000-5000" },
      // { label: "$5B - $10B", value: "5000-10000" },
      // { label: "$10B+", value: ">10000" },
    ],
    type: "select",
  },
  {
    field: "industry",
    headerName: "Industry",
    options: [
      { label: "", value: "" },
      { label: "Agriculture", value: "Agriculture" },
      { label: "Business Services", value: "Business Services" },
      { label: "Commercial Services", value: "Commercial Services" },
      { label: "Communications", value: "Communications" },
      { label: "Construction", value: "Construction" },
      { label: "Consumer Products", value: "Consumer Products" },
      { label: "Energy", value: "Energy" },
      { label: "Entertainment", value: "Entertainment" },
      { label: "Financial Services", value: "Financial Services" },
      { label: "Healthcare", value: "Healthcare" },
      { label: "IT Services", value: "IT Services" },
      { label: "Information Technology", value: "Information Technology" },
      { label: "Managed Network Services", value: "Managed Network Services" },
      { label: "Manufacturing", value: "Manufacturing" },
      { label: "Media", value: "Media" },
      { label: "Professional Services", value: "Professional Services" },
      { label: "Retail", value: "Retail" },
      { label: "Software", value: "Software" },
      { label: "Technology", value: "Technology" },
      { label: "Telecommunications", value: "Telecommunications" },
      { label: "Transportation", value: "Transportation" },
    ],
    type: "select",
  },
  {
    field: "cagr_3year",
    headerName: "CAGR (3 Year)",
    type: "select",
    options: [
      { label: "", value: "" },
      { label: "-1 to -10", value: "<0" },
      { label: "0 - 5", value: "0-5" },
      { label: "5 - 10", value: "5-10" },
      { label: "10 - 20", value: "10-20" },
      { label: "20+", value: ">20" },
    ],
  },
  {
    field: "revenue_percentage_marketing_spend",
    headerName: "Marketing Spend % of Revenue",
    type: "select",
    options: [
      { label: "", value: "" },
      { label: "< 15", value: "<15" },
      { label: "15 - 20", value: "15-20" },
      { label: "20 - 25", value: "20-25" },
      { label: "30 - 35", value: "30-35" },
      { label: "> 35", value: ">35" },
    ],
  },
]

const FORM_STATE: any = {
  account_type: "",
  fte: "",
  revenue_band: "",
  industry: "",
  cagr_3year: "",
  revenue_percentage_marketing_spend: "",
}

const FORM_STATE_ACME: any = {
  account_type: "",
  fte: "250-500",
  revenue_band: "100-500",
  industry: "",
  cagr_3year: "",
  revenue_percentage_marketing_spend: "",
}
const FORM_STATE_QWERTY: any = {
  account_type: "CORP",
  fte: "",
  revenue_band: ">1000",
  industry: "Software",
  cagr_3year: "",
  revenue_percentage_marketing_spend: "",
}

const FORM_STATE_FOOBAR: any = {
  account_type: "PE OWNED",
  fte: "",
  revenue_band: "",
  industry: "Telecommunications",
  cagr_3year: "",
  revenue_percentage_marketing_spend: "<15",
}

const formatMonetaryUsd = (input: string) => {
  return !!input && input.length && Number(input)
    ? `$${input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    : ""
}
const formatPercentage = (input: string) => {
  return !!input && input.length && Number(input)
    ? `${(Number(input) * 100).toFixed(2)}%`
    : ""
}

const styles = {}

export {
  BENCHMARK_COLS,
  SUMMARY_COLS,
  FIELDS,
  PEER_GROUP_STATS,
  FORM_STATE,
  FORM_STATE_ACME,
  FORM_STATE_FOOBAR,
  FORM_STATE_QWERTY,
  formatPercentage,
  formatMonetaryUsd,
  styles,
}
export type { DataField }
