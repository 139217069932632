import Amplify from "aws-amplify"
import React from "react"
import ReactDOM from "react-dom"
import App from "./components/App"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"

const amplifyConfig = {
  oauth: {},
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
  aws_appsync_authenticationType:
    process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
  aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY,
  aws_s3_user: process.env.REACT_APP_AWS_S3_USER,
  aws_s3_key: process.env.REACT_APP_AWS_S3_KEY,
  aws_s3_secret: process.env.REACT_APP_AWS_S3_SECRET,
  aws_s3_bucket: "",
}

Amplify.configure(amplifyConfig)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
