import { AmplifySignOut } from "@aws-amplify/ui-react"
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    background: "#222732",
    marginBottom: 30,
  },
  title: {
    flexGrow: 1,
  },
}))

export function Header({ onNav }: any) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: "#003887" }}>
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit" className={classes.title}>
            SBI Mercury Benchmarks
          </Typography>
          <Button
            onClick={() => onNav()}
            style={{
              minWidth: "153px",
              display: "inline-block",
              marginRight: 10,
              fontWeight: 600,
              textAlign: "center",
              whiteSpace: "nowrap",
              verticalAlign: "middle",
              touchAction: "manipulation",
              cursor: "pointer",
              userSelect: "none",
              color: "#fff",
              padding: "0.79rem",
              letterSpacing: "0.75px",
              borderRadius: 0,
              backgroundColor: "#337ab7",
            }}
          >
            Add Data
          </Button>
          <AmplifySignOut />
        </Toolbar>
      </AppBar>
    </div>
  )
}
