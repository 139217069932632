import "./styles.css"
import { withAuthenticator } from "@aws-amplify/ui-react"
import {
  Container,
  Dialog,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core"
import React, { useState } from "react"
import Edit from "../Edit"
import { Header } from "../Header"
import Table from "../Search"

const theme = createMuiTheme({
  palette: {
    type: "light",
  },
})

function App() {
  const [showEditForm, setShowEditForm] = useState(false)
  const toggleForm = () => {
    setShowEditForm(!showEditForm)
  }
  return (
    <div style={{ minHeight: "100vh", background: "#F7F7F7" }}>
      <ThemeProvider theme={theme}>
        <Header onNav={toggleForm} />
        <Container>
          <Table />
        </Container>
        <Dialog
          open={showEditForm}
          onClose={toggleForm}
          aria-labelledby="add-new-benchmark"
        >
          <Edit onClose={toggleForm} />
        </Dialog>
      </ThemeProvider>
    </div>
  )
}

export default withAuthenticator(App)
